import React from "react"
import { GiClick, GiReceiveMoney, GiSettingsKnobs } from "react-icons/gi"
const Passi = () => {
  return (
    <div>
      <div className="p-5">
        <div className="mx-4 p-0 md:p-4">
          <div className="flex items-center">
            <div className="flex items-center text-sky-400 relative justify-center">
              <div className="rounded-full transition duration-500 ease-in-out h-14 w-14 md:h-24 md:w-24 py-3 border-2 md:border-4 border-sky-400 ">
                <GiSettingsKnobs className="w-full h-full" />
              </div>
              <div className=" absolute top-0 text-center mt-20 md:mt-32 w-32 text-xs md:text-lg font-medium uppercase text-sky-400  ">
                Consulta
              </div>
            </div>
            <div className=" flex-auto border-t-4 transition duration-500 ease-in-out border-sky-400 "></div>
            <div className="flex items-center text-sky-600 relative justify-center">
              <div className="rounded-full transition duration-500 ease-in-out h-14 w-14 md:h-24 md:w-24 py-3 border-2 md:border-4 border-sky-600 ">
                <GiClick className="w-full h-full" />
              </div>
              <div className=" absolute top-0 text-center mt-20 md:mt-32 w-32 text-xs md:text-lg font-medium uppercase text-sky-600 ">
                Scegli
              </div>
            </div>
            <div className="flex-auto border-t-4 transition duration-500 ease-in-out border-sky-600 "></div>
            <div className="flex items-center text-sky-800 relative justify-center">
              <div className=" rounded-full transition duration-500 ease-in-out h-14 w-14 md:h-24 md:w-24 py-3 border-2 md:border-4 border-sky-800">
                <GiReceiveMoney className="w-full h-full" />
              </div>
              <div className=" absolute top-0 text-center mt-20 md:mt-32 w-32 text-xs md:text-lg font-medium uppercase text-sky-800 ">
                Risparmia
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Passi
